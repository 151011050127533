.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.gamebox {
  border: 1px solid #025be8;
  border-radius: 0px 10px 10px 10px;
  margin: -17px 0px !important;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.gamebox .btnGroup1 {
  display: flex;
  justify-content: space-around;
  font-size: 12.5px;
  column-gap: 5px;
  text-wrap: nowrap;
}

.gamebox .btnGroup2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: space-between !important;
  row-gap: 10px;
}

.btnGroup1 > span {
  background-color: #d7e8fc;
  border-radius: 5px;
  color: #0d1134;
  padding: 5px 15px;
  text-align: center;
}

.btnGroup2 div div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid #d7e8fc;
  border-radius: 50% !important;
  color: #d7e8fc;
  padding: 12px 14px 11px;
  font-size: 20px;
  min-width: 10px;
  min-height: 40px;
}

.btnGroup2 div {
  display: flex;
  flex-direction: column;
  gap: 5px !important;
  align-items: center;
  flex-basis: 20%;
  min-width: 20%;
}

.btnGroup2 span {
  font-size: 12px;
}

.active {
  background-color: #025be8 !important;
  color: #ffffff !important;
  border-color: #025be8 !important;
}

@media screen and (max-width: 400px) {
  .gamebox .btnGroup1 {
    column-gap: 8px;
  }

  .btnGroup1 span {
    font-size: 10px;
    padding: 5px 10px;
  }
}
