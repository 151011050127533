.countdownTimer {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.countdownTimerItem {
  margin: 0 3px;
  background: #f43e23;
  width: 25px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdownTimerSymbol {
  margin: 0 5px;
}
