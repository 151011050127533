.container {
    background-color: var(--mui-palette-common-background);
    min-height: 100vh;
    background-size: cover;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    padding: 0;
  }
  
  .contentContainer {
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-color: var(--mui-palette-common-background);
    background-image: var(--mui-palette-commonBg);
    max-width: 700px;
    margin: auto;
    position: relative;
  }
  
  .wrapper {
    box-sizing: border-box;
    padding: 0 17px 10px;
    margin: auto;
  }
  
  .wrapper2 {
    padding: 0 17px;
  }
  