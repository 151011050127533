.container {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.colorBetRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:global(.green) {
  background-color: #51bc16;
}

:global(.violet) {
  background-color: #b159e9;
}

:global(.red) {
  background-color: #f43e23;
}

.colorBet {
  width: 30%;
  border-radius: 5px;
  text-align: center;
  max-width: 120px;
  box-sizing: border-box;
  padding: 5px 0px;
}

.numBetWrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 25px;
}

.numBetItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

:global(.numBet) {
  width: 45px;
  height: 45px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 500;
}

:global(.mixred) {
  background: linear-gradient(-10deg, #f43e23, #f43e23 51%, #b159e9 51%);
}

:global(.mixgreen) {
  background: linear-gradient(-10deg, #51bc16, #51bc16 51%, #b159e9 51%);
}

.timerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
}

.timerBet {
  border-radius: 5px;
  border: 1px solid #51bc16;
  width: 36px;
  padding: 5px 0px;
  text-align: center;
  font-size: 14px;
}

.redTimer {
  border: 1px solid #f43e23;
  width: fit-content;
  padding: 5px 3px;
}

:global(.blue) {
  background-color: #33ccf2;
}

:global(.yellow) {
  background-color: #ecc62d;
}

.bigSmallRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.bigSmallBet {
  width: 177px;
  text-align: center;
  padding: 13px 0px;
  font-size: 18px;
}

.bigSmallRow .bigSmallBet:first-child {
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
}

.bigSmallRow .bigSmallBet:last-child {
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
}

.activeTimer {
  background-color: #51bc16;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #151747cc;
  opacity: 0.4;
  z-index: 1;
  /* pointer-events: none !important; */
}

@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
    border: 2px solid gold;
  }
  25% {
    transform: scale(0.85, 1.2);
    border: 2px solid gold;
  }
  50% {
    transform: scale(1.2, 0.85);
    border: 2px solid gold;
  }
  75% {
    transform: scale(0.95, 1.05);
    border: 2px solid gold;
  }
}

@keyframes gelatine2 {
  from,
  to {
    transform: scale(1, 1);
    border: 2px solid gold;
  }
  25% {
    transform: scale(0.85, 1.2);
    border: 2px solid gold;
  }
  50% {
    transform: scale(1.2, 0.85);
    border: 2px solid gold;
  }
  75% {
    transform: scale(0.95, 1.05);
    border: 2px solid gold;
  }
}

:global(.bounce0) {
  animation: gelatine 0.25s linear 0s, gelatine2 0.25s linear 2s;
}

:global(.bounce1) {
  animation: gelatine 0.25s linear 0.2s, gelatine2 0.25s linear 2.2s;
}

:global(.bounce2) {
  animation: gelatine 0.25s linear 0.4s, gelatine2 0.25s linear 2.4s;
}

:global(.bounce3) {
  animation: gelatine 0.25s linear 0.6s, gelatine2 0.25s linear 2.6s;
}

:global(.bounce4) {
  animation: gelatine 0.25s linear 0.8s, gelatine2 0.25s linear 2.8s;
}

:global(.bounce5) {
  animation: gelatine 0.25s linear 1s, gelatine2 0.25s linear 3s;
}

:global(.bounce6) {
  animation: gelatine 0.25s linear 1.2s, gelatine2 0.25s linear 3.2s;
}

:global(.bounce7) {
  animation: gelatine 0.25s linear 1.4s, gelatine2 0.25s linear 3.4s;
}

:global(.bounce8) {
  animation: gelatine 0.25s linear 1.6s, gelatine2 0.25s linear 3.6s;
}

:global(.bounce9) {
  animation: gelatine 0.25s linear 1.8s, gelatine2 0.25s linear 3.8s;
}
