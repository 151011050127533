.betBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.slotRoot {
  width: 100%;
  position: relative;
  z-index: 10 !important;
}

.slotRoot .sideBox {
  position: absolute;
  top: 41%;
  background-color: #013799;
  height: 50px;
  width: 20px;
  border-radius: 23px;
  z-index: -1;
}

.slotRoot .sideBoxLeft {
  left: -8px;
}

.slotRoot .sideBoxRight {
  right: -8px;
}

.slotRoot .arrow {
  position: absolute;
  z-index: 999;
  top: 48%;
}

.slotRoot .arrowLeft {
  left: 0;
}

.slotRoot .arrowRight {
  right: 0;
}

.slotContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
  background-color: #025be8;
  border-radius: 10px;
  padding: 8px;
  z-index: 2 !important;
}

.slotLabels {
  display: flex;
  justify-content: space-around;
}

.slotWrapper {
  display: flex;
  justify-content: space-between;
  background-color: #003086;
  border-radius: 5px;
  padding: 10px 6px;
  gap: 10px;
}

.slotWrapper .arrow {
  position: absolute;
  top: 45%;
}

.boxContainer {
  flex: 1 1 0%;
  border-radius: 5px;
  height: 150px;
  background-image: radial-gradient(circle, #424776, #0c113f);
  gap: 10px;
  overflow: hidden;
}

.boxes {
  transition: transform 1s ease-in-out;
}

.box {
  margin: 15px auto;
  display: block;
  border-radius: 9999px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  color: #000000;
  background-color: #d7e8fc;
  font-weight: 500;
  transform: translateY(-103px);
}

.boxContainer:nth-child(1) .box {
  background-color: #51bc16;
  color: #ffffff;
}

/* 14 PRO MAX */
@media screen and (max-width: 600px) {
  .box {
    width: 58px;
    height: 58px;
    transform: translateY(-55px);
    margin: 12px auto;
    font-size: 40px;
  }
  .boxContainer {
    height: 110px;
  }
}

/* IP SE */
@media screen and (max-width: 400px) {
  .box {
    width: 45px;
    height: 45px;
    font-size: 30px;
    transform: translateY(-48px);
  }
  .boxContainer {
    height: 90px;
  }
  .box {
    margin: 13px auto;
  }
}

@keyframes spin {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%); /* Adjust this value based on your needs */
  }
}

.spinning {
  animation: spin 2.5s linear;
}
