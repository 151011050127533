.container {
  margin: 0px;
  position: absolute;
  /* top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.winWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.winResult {
  font-size: 24px;
  font-weight: 900;
}

.winNum {
  width: 89px;
  height: 89px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-weight: 500;
  margin-top: 20px;
}
