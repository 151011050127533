.betWrapper {
  padding: 15px;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px 10px;
}
.title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.oddsTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;
  color: #2875ff;
}

.boxNumRow {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  justify-content: space-between;
}
.numItem {
  width: 50px;
  height: 40px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  color: #0d1134;
}
.numbox1 {
  background-color: #ecd7fc;
}
.numbox2 {
  background-color: #ffaba7;
}
.numbox3 {
  background-color: #d7fce6;
}
.remark {
  color: #c49516;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  padding-bottom: 10px;
}

.active {
  color: #ffffff !important;
}

.numbox1.active {
  background-color: #af4aff !important;
}
.numbox2.active {
  background-color: #ff463e !important;
}
.numbox3.active {
  background-color: #23a457 !important;
}
