.border {
  position: relative;
  z-index: 0;
  border-radius: 35px;
  overflow: hidden;
  border-color: transparent;
  border-width: 20px;
  border-style: solid;
}

.border::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-position: center center;
  background-image: linear-gradient(#f03 10%, #08f 90%);
  animation: bgRotate 2.5s linear infinite;
}

.border::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border-radius: 15px;
  background: #151747;
}

@keyframes bgRotate {
  100% {
    transform: rotate(1turn);
  }
}

@media (max-width: 640px) {
  .border::after {
    width: calc(100% - 5px);
    height: calc(100% - 5px);
  }
}
