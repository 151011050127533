.betWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  -ms-overflow-style: none;
  scrollbar-width: none;
  justify-items: center;
  gap: 0px 10px;
  padding: 10px;
}

.betItem {
  text-align: center;
  flex: 0 0 25%;
  padding: 0 0.08rem;
  margin-bottom: 0.16rem;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

.bgPrimary {
  background-color: #025be8 !important;
}

.textWhite {
  color: #ffffff !important;
}

.betInnerItem {
  width: 100%;
  height: 100%;
  background: #d7e8fc;
  border-radius: 4px;
  padding: 3px;
  box-sizing: border-box;
  color: var(--mui-palette-primary-main);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  position: relative;
}

.betInnerItem.action {
  color: #fff;
  background-color: #ccc;
}

.betInnerItem .betNames {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #0d1134;
}

.betInnerItem .betOdds {
  color: #676767;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

@media (max-width: 375px) {
  .betWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .betWrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}
