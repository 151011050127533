.gameBettingContainer {
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 15px;
  background-color: #151747;
}

.logoContainer {
  position: relative !important;
}

.logoContainer img {
  /* height: auto; */
  max-width: 100%;
}
.walletInfo {
  position: absolute !important;
  /* margin-left: 80px; */
  /* transform: translate(-50%, -50%); */
  bottom: 40px;
  left: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.totalBalance {
  font-weight: 400;
  font-size: 30px;
  color: #fafafa;
  margin-bottom: 2px;
}

.memberWallet {
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 12px;
  font-size: 2em;
  padding: 0 12px;
}

.memberWallet img {
  width: 40px;
  height: 40px;
  margin: 0px 5px 0px -1px;
}
.memberBalance {
  font-family: Roboto;
  font-weight: 500;
  font-size: 30px;
  color: #ffffff;
  padding: 0 5px;
}

/* .coin {
  position: absolute;
} */
.timePeriodContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
}

.timePeriodOption {
  display: flex;
  margin-top: 10px;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-around;
}
.timePeriodTitle {
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fafafa;
}

.gameRulesTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2875ff;
}

.gameRules {
  display: flex;
  gap: 5px;
}

.gameRules svg {
  padding-top: 2px;
}

.optionBox {
  flex: 1;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #1c2257;
  color: #ffffff;
  width: 80px;
  font-family: Roboto;
}

.selectedOption {
  background-color: #025be8;
  color: #ffffff;
}

.betContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.betBoxHeader {
  position: relative;
  width: 100%;
  border-bottom: 0.5px solid #2d346e;
  padding-bottom: 15px;
}

.betBoxTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #eeeeee;
}

.betMinDisplay {
  border-radius: 5px;
  border: 1px solid #ffffff;
  width: 66px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.betMinContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
}

.betPeriodID {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
}

.winningNotice {
  text-align: right;
  height: 10px;
}

.winningNotice .MuiSwitch-track {
  background-color: #fff !important;
}

@media screen and (max-width: 768px) {
  .walletInfo {
    bottom: 40px;
    left: 40px;
  }
}

@media screen and (max-width: 640px) {
  .walletInfo {
    bottom: 35px;
    left: 25px;
  }
  
  .memberWallet {
    font-size: 1.5em;
    border-radius: 10px;
    padding: 0 5px;
  }
  
  .totalBalance {
    font-size: 1.5em;
  }
  
  .memberBalance {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 480px) {
  .walletInfo {
    bottom: 30px;
    left: 25px;
  }
  
  .memberWallet {
    font-size: 1em;
    border-radius: 5px;
    padding: 0 5px;
  }
  
  .totalBalance {
    font-size: 1em;
  }
  
  .memberBalance {
    font-size: 1em;
  }
}

@media screen and (max-width: 400px) {
  .walletInfo {
    bottom: 25px;
    left: 20px;
  }

  .memberWallet {
    font-size: 1em;
  }

  .totalBalance {
    font-size: 1em;
  }

  .memberBalance {
    font-size: 1em;
  }
}
