.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #252a5e, #252a5e), linear-gradient(0deg, #2d346e, #2d346e);
  border: 1px solid #2d346e;
  border-radius: 8px;
  width: 90%;
  max-height: 80vh;
  max-width: 500px;
  padding: 20px;
  margin: auto;
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.innerContainer {
  width: 100%;
  overflow-y: auto;
}
.scrollableContent {
  overflow-y: auto;
  scrollbar-width: thin; /* Firefox */
}

.scrollableContent::-webkit-scrollbar {
  width: 9px;
}

.scrollableContent::-webkit-scrollbar-track {
  background: transparent;
}

.scrollableContent::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}
.alertBg {
  width: 100%;
  height: auto;
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.popupTitleImg {
  /* position: absolute; */
  /* top: 0; */
  /* left: 50%; */
  /* transform: translateX(-50%); */
  /* z-index: 2; */
  width: 80%;
  max-width: 300px;
}

.container {
  height: 100%;
  position: relative;
  align-items: center;
  outline: none;
}

.popupTitleContainer {
  top: -31px;
  position: absolute;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  /* align-items: center; */
  min-width: 300px;
  z-index: 2;
}
.preSalesRuleText {
  top: -10%;
  left: 29%;
  position: absolute;
  font-weight: bold;
  margin-bottom: 10px;
  z-index: 3;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #fab300;
  padding-top: 25px;
}
.gameRuleText {
  top: -10%;
  left: 30%;
  position: absolute;
  font-weight: bold;
  margin-bottom: 10px;
  z-index: 3;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #fab300;
  padding-top: 25px;
}
