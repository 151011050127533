.gameContainer {
  max-width: 700px;
  margin: 0 auto;
  padding: 10px;
  /* background-color: rgb(105, 39, 39); */
  background-color: #151747;
}

.diceContainer {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  text-align: center;
  margin-bottom: 15px;
}

.slotFrame {
  padding: 0.26667rem;
  background: #025be8;
  display: inline-block;
  border-radius: 0.18667rem;
  position: relative;
  margin-top: 0.53333rem;
}

.slotFrame:before {
  left: -0.16rem;
  border-radius: 0.16rem 0 0 0.16rem;
}

.slotFrame:after,
.slotFrame:before {
  position: absolute;
  top: 1.14667rem;
  width: 0.16rem;
  height: 0.8rem;
  content: "";
  background: #013799;
}

.slotFrame:after {
  right: -0.16rem;
  border-radius: 0 0.16rem 0.16rem 0;
}

.slotBox {
  padding: 0.16rem 0;
  background: linear-gradient(180deg, #003086, #003086);
  border-radius: 0.08rem;
  display: inline-block;
  position: relative;
}

.slotBox:before {
  left: -0.02667rem;
  border-right: 0.53333rem solid transparent;
  border-left: 0.53333rem solid #025be8;
}

.slotBox:after,
.slotBox:before {
  position: absolute;
  top: 1.09333rem;
  width: 0;
  height: 0;
  border-top: 0.18667rem solid transparent;
  border-bottom: 0.18667rem solid transparent;
  content: "";
  z-index: 3;
}

.slotBox:after {
  right: -0.02667rem;
  border-left: 0.53333rem solid transparent;
  border-right: 0.53333rem solid #025be8;
}

.slotColumn {
  display: inline-block;
  width: 2.34667rem;
  height: 2.26667rem;
  border-radius: 0.10667rem;
  overflow: hidden;
  background: #0c113f;
  margin-right: 0.10667rem;
  vertical-align: bottom;
  position: relative;
  text-align: center;
}

.slotColumn:before {
  box-shadow: inset 0 -0.18667rem 0.10667rem 0 rgba(0, 0, 0, 0.3), inset 0 0.18667rem 0.10667rem 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 101%;
  height: 101%;
  content: "";
  z-index: 2;
}

.slotColumn:first-child {
  margin-left: 0.16rem;
}
.slotColumn:last-child {
  margin-right: 0.16rem;
}
.slotColumn .slotNum {
  width: 1.44rem;
  height: 1.44rem;
  line-height: 1.44rem;
  background: #e1e1ec;
  border-radius: 50%;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 700;
  margin: 0 auto 0.21333rem;
}

.slotTransform {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(0.12rem);
}

.slotColumn .slotTransform.transform0 .slotNum {
  background: #00e065;
  color: #fff;
}
.slotColumn .slotTransform.slot-scroll[data-v-42f27458] {
  -webkit-animation: slotScroll-data-v-42f27458 3s cubic-bezier(0.65, 0.02, 0.65, 1.06);
  animation: slotScroll-data-v-42f27458 3s cubic-bezier(0.65, 0.02, 0.65, 1.06);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.slotColumn .slotTransform.slot-scroll.transform1[data-v-42f27458] {
  -webkit-animation-delay: 0.12s;
  animation-delay: 0.12s;
}
.slotColumn .slotTransform.slot-scroll.transform2[data-v-42f27458] {
  -webkit-animation-delay: 0.24s;
  animation-delay: 0.24s;
}
.slotColumn .slotTransform.slot-scroll.transform3[data-v-42f27458] {
  -webkit-animation-delay: 0.36s;
  animation-delay: 0.36s;
}
.slotColumn .slotTransform.slot-scroll.transform4[data-v-42f27458] {
  -webkit-animation-delay: 0.48s;
  animation-delay: 0.48s;
}

/* .slotNum img {
  width: 2.24rem;
  height: 2.24rem;
  line-height: 2.24rem;

  font-size: 0.8rem;

  font-weight: 700;
  margin: 0 auto 0.21333rem;
  text-indent: -266.64rem;
  background: no-repeat 50%;
} */
.slotNum {
  width: 1.44rem;
  height: 1.44rem;
  line-height: 1.44rem;
  background: #e1e1ec;
  border-radius: 50%;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 700;
  margin: 0 auto 0.21333rem;
}

.slotNum img {
  width: 100%;
  height: 100%;
  line-height: 100%;
}

.diceImg {
  height: 30px;
  width: 30px;
  padding: 5px;
}

.gamebox {
  border: 1px solid #025be8;
  border-radius: 10px;
  margin: -4px 0px;
}

.betNums {
  min-width: 40px;
  border-radius: 5px;
  text-align: center !important;
  padding: 5px 10px;
}

.game1 .betNums {
  background-color: #025be8;
}

.game2 .betNums,
.game3 .betNums,
.game4 .betNums {
  background-color: #af4aff;
}

.game2 .betNumsGradients {
  background-image: linear-gradient(to right, #ff463e 30%, #23a457);
}

.game3 .betBtn,
.game4 .betBtn {
  background-color: #ff463e;
  padding: 8px 5px;
  border-radius: 5px;
}

.betRoot {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 14px !important;
}

.betContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 12px;
}

.game1 .betContainer {
  max-width: 70%;
}

.game2 .betContainer,
.game3 .betContainer,
.game4 .betContainer {
  max-width: 65% !important;
}

.betWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.betRoot .betLabel {
  color: #b0bbcc !important;
}

.game2 .betLabel,
.game3 .betLabel,
.game4 .betLabel {
  max-width: 35% !important;
}

.betBox {
  position: relative;
}

.betBox .gameBlocker {
  width: 100%;
  height: 100%;
  position: relative;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #151747cc;
  opacity: 0.4;
  z-index: 1;
  /* pointer-events: none !important; */
}
