.loading {
  width: 72px;
  height: 72px;
  overflow: hidden;
  margin: 0 auto;
}

.loading::after {
  content: "";
  display: block;
  width: 5184px;
  height: 72px;
  will-change: transform;
  background-image: url("../../../../public/images/common/loading.png");
  background-position: 0 0px;
  background-repeat: no-repeat;
  background-size: 5184px 72px;
  -webkit-animation: sprite-loading 3s steps(72) both infinite;
  animation: sprite-loading 3s steps(72) both infinite;
}

@-webkit-keyframes sprite-loading {
  from {
    background-position: 0px;
  }
  to {
    background-position: -5184px;
  }
}

@keyframes sprite-loading {
  from {
    background-position: 0px;
  }
  to {
    background-position: -5184px;
  }
}
