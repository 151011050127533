.diceContainer {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  text-align: center;
  margin-bottom: 15px;
}
.wrapper {
  width: 100%;
  max-width: 387px;
  margin: auto;
  position: relative;
}

.wrapper .diceBg {
  width: 100%;
}

.diceRow {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  padding: 17px 27px 22px;
  box-sizing: border-box;
}

.dice {
  width: 100%;
  height: 100%;
  background: radial-gradient(
    67.58% 67.58% at 50% 50%,
    #424776 0%,
    #0c113f 100%
  );
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2.5px;
}

.arrowLeft,
.arrowRight {
  position: absolute;
  width: 30px;
}

.arrowLeft {
  left: 8px;
}

.arrowRight {
  right: 8px;
}
